<template>
  <div>
    <div class="p-4 flex justify-between">
      <div>Mis estudios</div>
      <Button :to="{ name: 'studies.new' }">Añadir</Button>
    </div>

    <div :key="key" v-for="(study, key) in studies">
      <div
        class="flex items-center pt-2 pb-2 pr-4 pl-4 transition-all hover:bg-gray-100 hover:shadow-lg"
      >
        <div
          class="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white"
        >
          <img v-if="study.image" class="w-8 h-8 " :src="study.image" alt="" />
          <template v-else>{{ study.institute[0] }}</template>
        </div>

        <div class="ml-4 flex-initial flex-shrink-1 w-full truncate">
          <div class="text-sm font-medium text-gray-900 truncate">
            {{ study.title }}
          </div>
          <div class="text-xs text-gray-500 truncate">
            {{ study.institute }}
          </div>
        </div>
        <div class="ml-4">
          <Dropdown>
            <template #activator>
              <DotsVertical class="text-red bg-red w-5" />
            </template>
            <template #content>
              <DropdownMenuItem
                is="router-link"
                :to="{ name: 'studies.edit', params: { id: key } }"
              >
                Editar
              </DropdownMenuItem>
              <DropdownMenuItem @click="confirmDeleteStudy(key)">
                Eliminar
              </DropdownMenuItem>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <Modal v-model:isOpen="isOpenModalDeleteStudy" type="warning">
      <template #title>Desea eliminar el estudio?</template>
      <template #content>
        Se eliminara el estudio permanentemente y no podra recuperar la
        informacion.
      </template>
      <template #footer>
        <button
          type="button"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm"
          @click="deleteStudy"
        >
          Eliminar
        </button>
        <button
          type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          @click="isOpenModalDeleteStudy = false"
        >
          Cancellar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'

// Studie sstore
import { STUDIES_STORE_NAME } from '@/store/modules/studies'
import { DELETE_STUDY } from '@/store/modules/studies/actions.type'

// Notifications store
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { PUSH_NOTIFICATION } from '@/store/modules/notifications/actions.type'

import DotsVertical from '@/components/icons/heroicons/DotsVertical.vue'
import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import Button from '@/components/common/button/Button.vue'
import Modal from '@/components/common/modal/Modal.vue'

export default {
  components: {
    Button,
    DotsVertical,
    Dropdown,
    DropdownMenuItem,
    Modal
  },
  setup() {
    const store = useStore()
    const studies = computed(() => store.getters[STUDIES_STORE_NAME + '/data'])
    const isOpenModalDeleteStudy = ref(false)
    const studyKey = ref(null)

    const isLoading = computed(
      () => store.getters[STUDIES_STORE_NAME + '/isLoading']
    )
    const isError = computed(
      () => store.getters[STUDIES_STORE_NAME + '/isError']
    )
    const isReady = computed(
      () => store.getters[STUDIES_STORE_NAME + '/isReady']
    )

    const confirmDeleteStudy = key => {
      studyKey.value = key
      isOpenModalDeleteStudy.value = true
    }
    const deleteStudy = async () => {
      await store.dispatch(
        STUDIES_STORE_NAME + '/' + DELETE_STUDY,
        studyKey.value
      )

      if (isReady.value) {
        studyKey.value = null
        isOpenModalDeleteStudy.value = false
        pushNotification({
          title: 'Estudio eliminado!',
          desciption: 'Tu estudio se ha eliminado correctamente ',
          type: 'success'
        })
      }
      if (isError.value) {
        pushNotification({
          title: 'Algun error ha ocurrido!',
          desciption: 'Vuelve a intentarlo o contacta a soporte',
          type: 'error'
        })
      }
    }

    function pushNotification(data) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + PUSH_NOTIFICATION, data)
    }

    return {
      studies,
      confirmDeleteStudy,
      isOpenModalDeleteStudy,
      deleteStudy,
      isLoading
    }
  }
}
</script>

<style></style>
